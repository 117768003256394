import { memo as Memo, useMemo, useRef, useCallback } from 'react'
import gsap from 'gsap'
import { useRouter } from 'next/router'

//* Components
import { Swiper, SwiperSlide } from 'swiper/react'
import { FreeMode } from 'swiper'
import Container from 'components/common/Container'
import Text from 'components/common/Text'
import Animate from 'components/common/Animate'
import Button from 'components/common/Button'
import ProjectItem from 'components/pages/Global/ProjectItem'

//* Style
import 'swiper/css'
import 'swiper/css/free-mode'
import RelatedProjectsStyle from './style'

const RelatedProjects = Memo(({ projects, slug, buttonName, sectionTitle, slider }) => {
	//! Router
	const router = useRouter()

	//! Refs
	const titleWrap = useRef()
	const items = useRef([])
	const cursorRef = useRef()
	const dragNumberSize = useRef(1)

	//! Mouse Move
	const handleContainerMouseMove = useCallback(
		(e) => {
			if (projects.length > dragNumberSize.current) {
				gsap.to(cursorRef.current, { duration: 0.3, opacity: 1, left: e.clientX, top: e.clientY })
			}
		},
		[cursorRef.current, projects.length, dragNumberSize.current]
	)

	//! Mouse Leave
	const handleContainerMouseLeave = useCallback(() => {
		if (projects.length > dragNumberSize.current) {
			gsap.killTweensOf([cursorRef.current])
			gsap.set(cursorRef.current, { opacity: 0 })
		}
	}, [cursorRef.current, projects.length, dragNumberSize.current])

	//! Store related slides
	const storeRelatedSlides = useMemo(() => {
		return projects.map((relatedProject, index) => {
			return (
				<Animate
					key={index}
					startPoint={'top bottom'}
					delay={0.3}
					startY={'20%'}
					stagger={0.13}
					params={{ opacityVertical: [items] }}
					dependency={router.asPath}
					durationOpacity={0.5}
				>
					<ProjectItem ref={(ref) => (items.current[index] = ref)} className={'related col-6 col-m-12'} project={relatedProject} />
				</Animate>
			)
		})
	}, [projects])

	//! Related projects store
	const relatedStore = useMemo(() => {
		return (
			<Container full={slider || false} row className={`related-wrap ${slider ? 'addWrap' : ''}`}>
				{slider ? (
					<div className={`galleryWrapper`} onMouseMove={handleContainerMouseMove} onMouseLeave={handleContainerMouseLeave}>
						<Swiper touchStartPreventDefault={false} modules={[FreeMode]} freeMode={true} slidesPerView={'auto'}>
							{projects.map((item, index) => {
								return (
									<SwiperSlide key={index} className={`col-6 col-m-12`}>
										<Animate
											startPoint={'top bottom'}
											delay={0.3}
											startY={'20%'}
											stagger={0.13}
											params={{ opacityVertical: [items] }}
											dependency={router.asPath}
											durationOpacity={0.5}
										>
											<ProjectItem ref={(ref) => (items.current[index] = ref)} className={'related'} project={item} />
										</Animate>
									</SwiperSlide>
								)
							})}
						</Swiper>

						{projects.length > dragNumberSize.current && (
							<span ref={cursorRef} className={'font-montserratarm-medium cursor-drag p'}>
								Drag
							</span>
						)}
					</div>
				) : (
					storeRelatedSlides
				)}
			</Container>
		)
	}, [slider, projects, dragNumberSize.current, storeRelatedSlides, router.asPath, cursorRef, items.current])

	return (
		projects.length !== 0 && (
			<RelatedProjectsStyle className={`relatedProjectsWrapper`} dragNumberSize={dragNumberSize.current} imagesQuantity={projects.length}>
				<div className={'slider-cont'}>
					<Container>
						<Animate
							startPoint={'+=20% bottom'}
							durationOpacity={0.4}
							startY={'50%'}
							params={{ opacityVertical: [titleWrap] }}
							dependency={router.asPath}
						>
							<div className='related-title-wrap' ref={titleWrap}>
								<Text tag={'h2'} className={'h5 related-news-title uppercase font-montserratarm-medium'} text={sectionTitle} />
								<Button url={slug} text={buttonName} />
							</div>
						</Animate>
					</Container>

					{relatedStore}
				</div>
			</RelatedProjectsStyle>
		)
	)
})

export default RelatedProjects
