import { forwardRef } from 'react'

//* Helpers
import { config } from 'helpers'

//* Components
import Text from 'components/common/Text'
import Image from 'components/common/Image'
import Icon from 'components/common/Icon'
import CustomLink from 'components/common/CustomLink'

//* Style
import ProjectItemStyle from './style'

const Projects = forwardRef(({ className, project }, ref) => {
	return (
		<ProjectItemStyle ref={ref} className={className || ''}>
			<CustomLink url={config.routes.projects.path + '/' + project.slug}>
				<div className='project-item'>
					<Image className={'project-image'} objectFit={'cover'} src={project.featured_image?.url} alt={project.featured_image?.alt} />
					<div className='project-content'>
						<div className='project-content-top'>
							<Text
								className={'p1 font-montserratarm-medium primaryColor1 project-item-title uppercase'}
								text={project.featured_title}
							/>
							<Icon name={'arrow'} className={'project-icon'} />
						</div>
						<div className='project-content-bottom'>
							<div className='date-block'>
								<Text className={'p font-montserratarm-light primaryColor1 uppercase'} text={'date'} />
								<Text className={'p font-montserratarm-semiBold primaryColor1 uppercase date'} text={project.date} />
							</div>
							<div className='service-block'>
								<Text className={'p font-montserratarm-light primaryColor1 uppercase'} text={'service'} />
								<Text className={'p font-montserratarm-semiBold primaryColor1 uppercase service'} text={project.service} />
							</div>
							<div className='partner-block'>
								<Text className={'p font-montserratarm-light primaryColor1 uppercase'} text={'partner'} />
								<Text className={'p font-montserratarm-semiBold primaryColor1 uppercase partner'} text={project.partner} />
							</div>
						</div>
					</div>
				</div>
			</CustomLink>
		</ProjectItemStyle>
	)
})

export default Projects
