import styled from 'styled-components'

const RelatedProjectsStyle = styled.div`
	--relatedTitleWrapPaddingBottom: var(--sp10x);
	--distance: var(--sp4x);

	//! Drag Cursor
	--dcSize: var(--sp13x);

	margin-bottom: var(--sectionsDistance);

	.galleryWrapper {
		* {
			cursor: ${(props) => (props.imagesQuantity > props.dragNumberSize ? 'none' : 'default')};
		}
	}

	.cursor-drag {
		display: flex;
		align-items: center;
		justify-content: center;
		position: fixed;
		top: calc(-1 * var(--dcSize));
		left: calc(-1 * var(--dcSize));
		width: var(--dcSize);
		height: var(--dcSize);
		background-color: var(--primaryColor1);
		color: var(--primaryColor2);
		border-radius: 50%;
		transform: translate(-50%, -50%) rotate(-45deg);
		z-index: 1000;
		pointer-events: none;
	}

	@media not all and (hover: hover) {
		.cursor-drag {
			display: none;
		}
	}

	.related-title-wrap {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-bottom: var(--relatedTitleWrapPaddingBottom);
	}

	.slider-cont {
		.addWrap {
			.galleryWrapper {
				.swiper {
					margin-left: calc(-1 * calc(var(--distance) / 2));
					margin-right: calc(-1 * calc(var(--distance) / 2));

					.swiper-slide {
						padding: 0 calc(var(--distance) / 2);

						&:first-child {
							margin-left: var(--containerPadLR);
						}

						&:last-child {
							margin-right: var(--containerPadLR);
						}
					}
				}
			}
		}
	}

	@media only screen and (min-width: ${({ theme }) => theme.mediaQuery.DesktopSizeL}) and (max-width: ${({ theme }) =>
			theme.mediaQuery.DesktopSizeXLMin}) {
		--relatedTitleWrapPaddingBottom: var(--sp8x);
		--distance: var(--sp4x);
	}

	@media only screen and (min-width: ${({ theme }) => theme.mediaQuery.DesktopSizeM}) and (max-width: ${({ theme }) =>
			theme.mediaQuery.DesktopSizeLMin}) {
		--relatedTitleWrapPaddingBottom: var(--sp5x);
		--distance: var(--sp3x);
	}

	@media only screen and (min-width: ${({ theme }) => theme.mediaQuery.DesktopSizeS}) and (max-width: ${({ theme }) =>
			theme.mediaQuery.DesktopSizeMMin}) {
		--relatedTitleWrapPaddingBottom: var(--sp5x);
		--distance: var(--sp3x);
	}

	@media only screen and (min-width: ${({ theme }) => theme.mediaQuery.DesktopSizeXS}) and (max-width: ${({ theme }) =>
			theme.mediaQuery.DesktopSizeSMin}) {
		--relatedTitleWrapPaddingBottom: var(--sp5x);
		--distance: var(--sp3x);
	}

	@media only screen and (min-width: ${({ theme }) => theme.mediaQuery.TabletSize}) and (max-width: ${({ theme }) =>
			theme.mediaQuery.DesktopSizeXSMin}) {
		--relatedTitleWrapPaddingBottom: var(--sp5x);
		--distance: var(--sp3x);

		//! Drag Cursor
		--dcSize: var(--sp10x);

		.related-title-wrap {
			flex-direction: column;
			align-items: flex-start;

			.related-news-title {
				margin-bottom: var(--sp4x);
			}
		}
	}

	@media only screen and (min-width: ${({ theme }) => theme.mediaQuery.TabletSizeS}) and (max-width: ${({ theme }) =>
			theme.mediaQuery.TabletSizeMin}) {
		--relatedTitleWrapPaddingBottom: var(--sp5x);
		--distance: var(--sp3x);

		//! Drag Cursor
		--dcSize: var(--sp10x);

		.related-title-wrap {
			flex-direction: column;
			align-items: flex-start;

			.related-news-title {
				margin-bottom: var(--sp4x);
			}
		}
	}

	@media only screen and (max-width: ${({ theme }) => theme.mediaQuery.TabletSizeSMin}) {
		--relatedTitleWrapPaddingBottom: var(--sp5x);
		--distance: var(--sp3x);

		//! Drag Cursor
		--dcSize: var(--sp10x);

		.related-title-wrap {
			flex-direction: column;
			align-items: flex-start;

			.related-news-title {
				margin-bottom: var(--sp3x);
			}
		}
	}
`

export default RelatedProjectsStyle
