import styled from 'styled-components'

const ProjectItemStyle = styled.div`
	--projectItemContentPadding: var(--sp5x) var(--sp5x) var(--sp7x) var(--sp5x);
	--projectItemTitleMaxWidth: var(--sp115x);
	--projectIconFontSize: var(--sp7x);
	--projectItemDateMaxWidth: var(--sp23x);
	--projectItemDateMarginRight: var(--sp3x);
	--projectItemServiceMaxWidth: var(--sp45x);
	--projectItemServiceMarginRight: var(--sp11x);
	--projectItemPartnerServiceDateMarginTop: var(--sp2x);
	--partnerWidth: var(--sp52x);
	--projectItemImagePaddingTop: 63.1%;

	overflow: hidden;

	&.related {
		.project-item {
			margin-top: 0;
		}
	}

	@media (hover: hover) {
		&:hover {
			.project-item {
				.project-image {
					transform: scale(1.2);
				}
			}
		}
	}

	.project-item {
		position: relative;
		overflow: hidden;

		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			width: 100%;
			height: 25%;
			z-index: 2;
			background: linear-gradient(to bottom, var(--layerColor), transparent);
			opacity: 0.9;
		}

		&:after {
			content: '';
			position: absolute;
			left: 0;
			bottom: 0;
			right: 0;
			width: 100%;
			height: 25%;
			z-index: 2;
			background: linear-gradient(to top, var(--layerColor), transparent);
			opacity: 1;
		}

		.project-image {
			padding-top: var(--projectItemImagePaddingTop);
			transition: 500ms;
			backface-visibility: hidden;
		}

		.project-content {
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			z-index: 3;
			padding: var(--projectItemContentPadding);

			.project-content-top {
				display: flex;
				justify-content: space-between;

				.project-item-title {
					max-width: var(--projectItemTitleMaxWidth);
				}

				.project-icon {
					font-size: var(--projectIconFontSize);
				}
			}

			.project-content-bottom {
				display: flex;
				justify-content: space-between;

				.partner,
				.date,
				.service {
					margin-top: var(--projectItemPartnerServiceDateMarginTop);
				}

				.partner {
					width: var(--partnerWidth);
					overflow: hidden;
					display: inline-block;
					text-overflow: ellipsis;
					white-space: nowrap;
				}

				.date-block {
					max-width: var(--projectItemDateMaxWidth);
					margin-right: var(--projectItemDateMarginRight);
					width: 100%;
				}

				.service-block {
					max-width: var(--projectItemServiceMaxWidth);
					width: 100%;
					margin-right: var(--projectItemServiceMarginRight);
				}
			}
		}
	}

	@media only screen and (min-width: ${({ theme }) => theme.mediaQuery.DesktopSizeL}) and (max-width: ${({ theme }) =>
			theme.mediaQuery.DesktopSizeXLMin}) {
		--projectItemContentPadding: var(--sp4x) var(--sp4x) var(--sp6x) var(--sp4x);
		--projectItemTitleMaxWidth: var(--sp85x);
		--projectIconFontSize: var(--sp6x);
		--projectItemDateMaxWidth: var(--sp20x);
		--projectItemDateMarginRight: var(--sp2x);
		--projectItemServiceMaxWidth: var(--sp34x);
		--projectItemServiceMarginRight: var(--sp6x);
		--projectItemPartnerServiceDateMarginTop: var(--sp1x);
		--partnerWidth: var(--sp37x);
		--projectItemImagePaddingTop: 63.3%;
	}

	@media only screen and (min-width: ${({ theme }) => theme.mediaQuery.DesktopSizeM}) and (max-width: ${({ theme }) =>
			theme.mediaQuery.DesktopSizeLMin}) {
		--projectItemContentPadding: var(--sp4x) var(--sp4x) var(--sp5x) var(--sp4x);
		--projectItemTitleMaxWidth: var(--sp64x);
		--projectIconFontSize: var(--sp5x);
		--projectItemDateMaxWidth: var(--sp13x);
		--projectItemDateMarginRight: var(--sp4x);
		--projectItemServiceMaxWidth: var(--sp30x);
		--projectItemServiceMarginRight: var(--sp3x);
		--projectItemPartnerServiceDateMarginTop: var(--sp1x);
		--partnerWidth: var(--sp24x);
		--projectItemImagePaddingTop: 63.3%;
	}

	@media only screen and (min-width: ${({ theme }) => theme.mediaQuery.DesktopSizeS}) and (max-width: ${({ theme }) =>
			theme.mediaQuery.DesktopSizeMMin}) {
		--projectItemContentPadding: var(--sp3x) var(--sp3x) var(--sp4x) var(--sp3x);
		--projectItemTitleMaxWidth: var(--sp67x);
		--projectIconFontSize: var(--sp5x);
		--projectItemDateMaxWidth: var(--sp12x);
		--projectItemDateMarginRight: var(--sp8x);
		--projectItemServiceMaxWidth: var(--sp28x);
		--projectItemServiceMarginRight: var(--sp4x);
		--projectItemPartnerServiceDateMarginTop: var(--sp1x);
		--partnerWidth: var(--sp25x);
		--projectItemImagePaddingTop: 63.3%;
	}

	@media only screen and (min-width: ${({ theme }) => theme.mediaQuery.DesktopSizeXS}) and (max-width: ${({ theme }) =>
			theme.mediaQuery.DesktopSizeSMin}) {
		--projectItemContentPadding: var(--sp3x);
		--projectItemTitleMaxWidth: var(--sp46x);
		--projectIconFontSize: var(--sp3x);
		--projectItemDateMaxWidth: var(--sp12x);
		--projectItemDateMarginRight: var(--sp5x);
		--projectItemServiceMaxWidth: var(--sp25x);
		--projectItemServiceMarginRight: var(--sp3x);
		--projectItemPartnerServiceDateMarginTop: var(--sp1x);
		--partnerWidth: var(--sp24x);
		--projectItemImagePaddingTop: 63.6%;
	}

	@media only screen and (min-width: ${({ theme }) => theme.mediaQuery.TabletSize}) and (max-width: ${({ theme }) =>
			theme.mediaQuery.DesktopSizeXSMin}) {
		--projectItemContentPadding: var(--sp2x) var(--sp2x) var(--sp3x) var(--sp2x);
		--projectItemTitleMaxWidth: var(--sp33x);
		--projectIconFontSize: var(--sp3x);
		--projectItemDateMaxWidth: unset;
		--projectItemDateMarginRight: unset;
		--projectItemServiceMaxWidth: unset;
		--projectItemServiceMarginRight: unset;
		--projectItemPartnerServiceDateMarginTop: var(--sp1x);
		--partnerWidth: 100%;
		--projectItemImagePaddingTop: 108.5%;

		.project-item {
			.project-content {
				.project-content-bottom {
					flex-direction: column;

					.date-block {
						margin-bottom: var(--sp3x);
					}

					.service-block {
						margin-bottom: var(--sp3x);
					}
				}
			}
		}
	}

	@media only screen and (min-width: ${({ theme }) => theme.mediaQuery.TabletSizeS}) and (max-width: ${({ theme }) =>
			theme.mediaQuery.TabletSizeMin}) {
		--projectItemContentPadding: var(--sp2x) var(--sp2x) var(--sp2-5x) var(--sp2x);
		--projectItemTitleMaxWidth: var(--sp33x);
		--projectIconFontSize: var(--sp3x);
		--projectItemDateMaxWidth: unset;
		--projectItemDateMarginRight: unset;
		--projectItemServiceMaxWidth: unset;
		--projectItemServiceMarginRight: unset;
		--projectItemPartnerServiceDateMarginTop: var(--sp1x);
		--partnerWidth: 100%;
		--projectItemImagePaddingTop: 146.3%;

		.project-item {
			.project-content {
				.project-content-bottom {
					flex-direction: column;

					.date-block {
						margin-bottom: var(--sp2-5x);
					}

					.service-block {
						margin-bottom: var(--sp2-5x);
					}
				}
			}
		}
	}

	@media only screen and (max-width: ${({ theme }) => theme.mediaQuery.TabletSizeSMin}) {
		--projectItemContentPadding: var(--sp2x) var(--sp2x) var(--sp3x) var(--sp2x);
		--projectItemTitleMaxWidth: var(--sp39x);
		--projectIconFontSize: var(--sp3x);
		--projectItemDateMaxWidth: unset;
		--projectItemDateMarginRight: unset;
		--projectItemServiceMaxWidth: unset;
		--projectItemServiceMarginRight: unset;
		--projectItemPartnerServiceDateMarginTop: var(--sp1x);
		--partnerWidth: 100%;
		--projectItemImagePaddingTop: 115.4%;

		margin-bottom: var(--sp3x);

		&:last-child {
			margin-bottom: 0;
		}

		.project-item {
			.project-content {
				.project-content-bottom {
					flex-direction: column;

					.date-block {
						margin-bottom: var(--sp3x);
					}

					.service-block {
						margin-bottom: var(--sp3x);
					}
				}
			}
		}
	}
`

export default ProjectItemStyle
